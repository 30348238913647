module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.frontierinternetservice.com"},
    },{
      plugin: require('../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-MCL9JK","brandTokens":["FTR","FTRB"],"mapiBrandToken":"FTR","siteName":"frontierinternetservice","alternateName":"frontierinternetservice.com","siteURL":"https://www.frontierinternetservice.com","defaultTitleTemplate":"","defaultPhone":"8553627979","phoneSymbol":"-","defaultPromoCode":"59881","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10045798","vwoProjectID":"894940","datasetManagerAPIKey":"358ea773800344e78f353d315727621b","chatEnabled":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"frontierinternetservice","short_name":"frontierinternetservice","start_url":"/","background_color":"#ff0037","theme_color":"#ff0037","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a71c3250e67b30121836d9753a3f9b44"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
